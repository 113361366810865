import { MachineCart, MachinePaymentMode } from "ui/src/types";
import { Fragment, ReactNode, useState } from "react";
import * as styles from './Steps.module.scss';
import cn from 'classnames';
import { Card } from "../Components";

export type IStep = {
    cart: MachineCart;
    updateCart: () => Promise<void>;
    proceed: () => void;
    goBack: () => void;
    enableNextStep?: (value: boolean) => void;
};

export const useValidation = (message: string, regex?: RegExp, regexMessage?: string) => {
    const [errorMessages, setErrorMessages] = useState<Record<string, string | undefined>>({});

    const f = (id: string) => ({
        onBlur: (fe: React.FocusEvent<HTMLInputElement>) => {
            if (!fe.target.value) {
                setErrorMessages({
                    ...errorMessages,
                    [id]: message
                });
            } else if ((!!regex && !regex.test(fe.target.value))) {
                setErrorMessages({
                    ...errorMessages,
                    [id]: regexMessage ?? message
                });
            } else {
                setErrorMessages({
                    ...errorMessages,
                    [id]: undefined
                });
            }
        },
        error: errorMessages[id]
    });

    return Object.assign(f, {
        setErrorMessage: (id: string, error: string | undefined) => setErrorMessages({
            ...errorMessages,
            [id]: error
        }),
        hasAnyErrors: () => Object.values(errorMessages).some(e => !!e)
    });
}

export const OrderSummary = ({ cart, step, proceed, validateCart, goBack }: { cart: MachineCart, step: number, proceed: (() => void) | undefined, validateCart: () => Promise<boolean>, goBack: () => void }) => {
    const [termsAgreed, setTermsAgreed] = useState(false);
    const translations = window.app.preloadState.translation;
    const machineSalesCheckout = window.app.preloadState.machineSalesCheckout;

    const tryProceed = async () => {
        if (!proceed) return;
        if (step == 4 && !termsAgreed) {
            return;
        }
        const valid = await validateCart();
        if (valid) {
            proceed();
        }
    }

    const updateTermsAndConditions = () => {
        setTermsAgreed(!termsAgreed);
    }

    return <Card>
        {cart.lineItems.map(item => <Fragment key={item.code}>
            <div className={styles.machineHeader}>
                <span>{item.quantity}</span>
                <span>x</span>
                <span>{item.displayName}</span>
            </div>
            {item.paymentMode === MachinePaymentMode.Direct
                ? <div className={styles.machineRow}>
                    <span>{translations["machineSalesCheckout.directPurchase"]}</span>
                    <span>{item.price}</span>
                </div>
                : <>
                    <div className={styles.machineRow}>
                        <span>{translations["machineSalesCheckout.monthlyCost"]}</span>
                        <span>{item.financeOptions.monthlyPaymentWithCurrency}</span>
                    </div>
                    <div className={styles.machineRow}>
                        <span>{translations["machineSalesCheckout.firstInstalment"]}</span>
                        <span>{item.financeOptions.discountedFirstInstalmentWithCurrency}</span>
                    </div>
                </>}
            {cart.promotionCode && item.discount && <div className={styles.machineRow}>
                <span>{translations["machineSalesCheckout.discount"]} (<b>{cart.promotionCode}</b>)</span>
                <span>- {item.discountWithCurrency}</span>
            </div>}
        </Fragment>)}
        <div className={styles.deliveryRow}>
            <span>{translations["machineSalesCheckout.delivery"]}</span>
            <span>{step >= 3 ? cart.summary.deliveryCost : translations["machineSalesCheckout.calculatedInNextStep"]}</span>
        </div>
        <hr/>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.directPurchase"]}</span>
            <span>{cart.summary.totalCost}</span>
        </div>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.monthlyCost"]}</span>
            <span>{cart.summary.totalMonthlyCost}</span>
        </div>
        <div className={styles.totalRow}>
            <span>{translations["machineSalesCheckout.total"]} {translations["machineSalesCheckout.firstInstalment"]}</span>
            <span>{cart.summary.totalFirstInstallment}</span>
        </div>
        {step === 4 && <div>
            <input id="terms-and-conditions" type="checkbox" className={styles.checkboxFix} checked={termsAgreed}
                   onChange={() => updateTermsAndConditions()}></input>
            <label htmlFor="terms-and-conditions">
                <small>
                    {translations["machineSalesCheckout.termsAndConditionsLabel"]} <a
                    style={{textDecoration: "underline"}}
                    href={machineSalesCheckout.termsAndConditionsUrl}>{translations["machineSalesCheckout.termsAndConditionsLink"]}</a>
                </small>
            </label>
        </div>
        }
        <button
            className={cn("btn btn--primary", styles.bold, (!proceed || (step == 4 && !termsAgreed)) && styles.buttonDisabled)}
            onClick={tryProceed}>{step === 4 ? translations["machineSalesCheckout.placeOrder"] : translations["machineSalesCheckout.nextStep"]}</button>
        {step !== 4 && <span>{translations["machineSalesCheckout.or"]} <a className={styles.link}
                                                                          onClick={goBack}>{translations["machineSalesCheckout.goBackStep"]}</a> ←</span>}
    </Card>
};