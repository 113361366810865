export type StockAvailability = {
    availability: string;
    hasEnoughInStock: boolean
}

export type LineItem = {
    code: string;
    displayName: string;
    url: string;
    imageUrl: string | null;
    quantity: number;
    itemUnitId: string | null;
    unitId: string | null;
    unitBasePrice: string;
    unitDiscountedPrice: string;
    totalPrice: string;
    discountPercentage: number;
    discountPercentageDisplay: string;
    hasDiscount: boolean;
    labels: { text: string, url: string }[];
    inventory: Inventory | null;
    validationMessages: ValidationMessage[];
    unitPriceAmount: number;
    categories: string[];
    isRemanItem: boolean;
    hasRemanItem: boolean;
    remanQuantity: number | null;
};

export type KitBundle = {
    bundleCode: string;
    kitCode: string;
    displayName: string;
    imageUrl: string | null;
    totalPrice: string;
    totalDiscountedPrice: string;
    totalDiscount: number | null;
    percentageDiscount: number | null;
    hasDiscount: boolean;
    inventory: Inventory | null;
    kitLineItems: KitLineItem[];
    kitContains: number;
    kitUrl: string | null;
};

export type KitLineItem = {
    lineItem: LineItem;
    bundleCode: string;
    kitCode: string;
    serialNumber: string;
};

export type RecommendedLineItem = LineItem & {
    showLogin: boolean;
    showNotAuthorized: boolean;
    showRequestQuotationByEmail: boolean;
    showContactSupport: boolean;
    showBuyButton: boolean;
    showAddToCartButton: boolean;
    showNotAvailableDisabled: boolean;
    requestQuotationByEmail: string | null;
    contactSupportLink: string | null;
}

export type SearchResultItem = {
    url: string;
    imageUrl: string;
    displayName: string;
    name: string;
    code: string;
    itemUnitId: string | null;
    unitPrice: string | null;
    discountedPrice: string | null;
    percentageDiscount: number;
    discountPercentageDisplay: string;
    isInCart: boolean;
    quantity: number;
    labels: { text: string, url: string }[];
    inventory: Inventory | null;
    showLogin: boolean;
    showNotAuthorized: boolean;
    showRequestQuotationByEmail: boolean;
    showContactSupport: boolean;
    showBuyButton: boolean;
    showNotAvailableDisabled: boolean;
    showAddToCartButton: boolean;
    requestQuotationByEmail: string | null;
    contactSupportLink: string | null;
    hasRemanItem: boolean;
    remanQuantity: number | null;
};

export type Inventory = {
    minimumQuantity: number;
    maximumQuantity: number;
    packageQuantity: number;
    quantityStep: number;
    warehouse: InventoryWarehouse | null;
    branch: InventoryBranch | null;
}

export type InventoryWarehouse = {
    isAvailable: boolean;
    availabilityDisplay: string | null;
    quantity: number;
};

export type InventoryBranch = {
    isAvailable: boolean;
    hasEnoughStock: boolean;
    isBranchSelected: boolean;
    branchName: string | null;
    quantity: number;
    labels: string[];
};

export type ValidationMessage = {
    message: string;
    redirectUri: string;
}

export type CartSummary = {
    lineItemsCount: number;
    depositItemsCount: number;
    listPricesTotal: string;
    depositPricesTotal: string;
    discountTotal: string;
    subTotal: string;
    taxTotal: string;
    shippingTotal: string;
    total: string;
    totalAmount: number;
    remanTaxTotal: string | null;
};

export type DeliveryOption = {
    displayName: string;
    id: string;
    isSelected: boolean;
};

export type DeliveryMethod = {
    isSelected: boolean;
    isAvailable: boolean;
    methodType: number;
    methodName: string;
    options: DeliveryOption[];
}

type BillingAddress = {
    name: string | null;
    streetAndHouse: string | null;
    city: string | null;
    postalCode: string | null;
    country: string | null;
}

export type BillingInformation = {
    customerEmail: string | null;
    customerPhoneNumber: string | null;
    customerPurchaseOrderNumber: string | null;
    isCustomerPurchaseOrderNumberRequired: boolean;
    customerOrderReference: string | null;
    billingAddress: BillingAddress | null;
    emailRegex: string;
};

export type Cart = {
    isMatomoEnabled: boolean,
    lineItems: LineItem[],
    kits: KitBundle[],
    summary: CartSummary,
    customerInformation: BillingInformation,
    validationMessages: ValidationMessage[],
    deliveryMethods: DeliveryMethod[],
    orderClasses: OrderClass[],
    machineInfo: MachineInfo | null
    selectedDeliveryOption: DeliveryOptionType | null,
    branchContactInfo: BranchContactInfo,
    rfq: Rfq;
    orderClassMachineSuggestions: SelectedVehicle[];
}

export type Rfq = {
    note: string | null;
    shouldSplitRfqOrder: boolean;
    hasItemWithPrice: boolean;
    hasItemWithoutPrice: boolean;
}

export type SelectedVehicle = {
    catalogIds: string[];
    operationModel: string | null;
    modelYear: string | null;
    customName: string | null;
    image: string | null;
    modelVariant: string | null;
    serialNumber: string | null;
    salesModel: string | null;
    partsCode: string | null;
    displayName: string;
};

export type OrderClass = {
    value: string;
    displayName: string;
    description: string;
    machineInfoRequired: boolean;
    isSelected: boolean;
}

export type MachineInfo = {
    machineModel: string | null;
    serialNumber: string | null;
}

export type RecommendedMachine = {
    displayName: string;
    itemNumber: string;
    url: string;
    imageUrl: string;
    showVolvoIcon: boolean;
    deliveryTime: string | null;
    price: string | null;
    showPrice: boolean;
    showPriceForGuestUsers: boolean;
    machineProperties: MachineProperty[];
    category: string | null;
    requestQuotationByEmail: string | null;
}

export type MachineProperty = {
    name: string;
    unit: string;
    min: number;
    max: number;
    value: number;
}

export enum DeliveryOptionType {
    Partial,
    Full
}

export enum DeliveryMethodSubTypes {
    Taxi,
    Carrier,
    NightExpress,
    PackageDeliveryProvider
}

export type RemanChoices = Partial<Record<string, RemanItemChoice>>;

export type RemanResults = Record<string, { selectedReman: boolean, sparePartQuantity: number, remanQuantity: number }>;

export type RemanItemChoice = {
    sparePartDetails: RemanufacturedPopUpItemDetails;
    remanPartDetails: RemanufacturedPopUpItemDetails;
    depositDetails: RemanufacturedPopUpItemDetails;
}

export type RemanufacturedPopUpItemDetails = {
    code: string;
    description: string;
    displayName: string;
    quantity: number;
    unit: string;
    availability: string | null;
    branch: string | null;
    isInBranch: boolean;
    price: string | null;
}

export type MachineCart = {
    lineItems: MachineLineItem[],
    summary: MachineCartSummary,
    userInformation: MachineCartUserInformation;
    deliveryMethod: MachineCartDelivery,
    promotionCode?: string,
    note: string;
    addNewAddressMySweconLink: string | null;
    validationMessages?: MessageRecord[]
}

export type MessageRecord = {
    message: string,
    redirectUri: string
}

export type MachineCartSummary = {
    lineItemsCount: number;
    deliveryCost?: string;
    totalMonthlyCost: string;
    totalFirstInstallment: string;
    totalDiscountCost: string;
    totalCost: string;
}

export type MachineCartUserInformation = {
    companyName?: string;
    emailAddress?: string;
    phoneNumber?: string;
    referenceNumber?: string;
    purchaseNumber?: string;
    invoiceAddress?: string;
    firstName?: string;
    lastName?: string;
    streetAndNumber?: string;
    town?: string;
    zipCode?: string;
    country?: string;
    createSweconAccount?: boolean;
}

export enum MachinePaymentMode {
    Direct,
    Monthly
}

export type MachineLineItem = {
    url: string;
    imageUrl: string;
    displayName: string;
    code: string;
    machineDeliveryInfo: string;
    quantity: number;
    discount: number;
    discountWithCurrency: string;
    validationMessages: string[];
} & (
    {
        paymentMode: MachinePaymentMode.Direct;
        price: string;
        discountedPrice: string;
        financeOptions: null;
    } | {
        paymentMode: MachinePaymentMode.Monthly;
        financeOptions: FinanceOptions;
    }
)

export type FinanceOptions = {
    price?: number | null;
    priceWithCurrency?: string;
    discountedFirstInstalment?: number | null;
    discountedFirstInstalmentWithCurrency?: number;
    downPaymentAmount?: number | null;
    downPaymentOption?: number | null;
    financedAmount?: number | null;
    insuranceAmount?: number | null;
    monthlyAnnuityPayment?: number | null;
    monthlyPayment?: number | null;
    numberOfMonthsOnTerm?: number | null;
    placedPriceFirstInstalment?: number | null;
    placedPriceFirstInstalmentWithCurrency?: string;
    totalCost?: number | null;
    yearlyInterestRatePercentage?: number | null;
    financedAmountWithCurrency?: string,
    monthlyPaymentWithCurrency?: string,
    firstInstalment?: number,
    firstInstalmentWithCurrency?: string,
    insuranceCost?: number,
    insuranceCostWithCurrency?: string,
    totalTax?: number,
    totalTaxWithCurrency?: string,
}

export enum MachineCartDeliveryMethod {
    Delivery,
    PickUp
}

export type MachineCartDelivery = {
    selectedDeliveryMethodType: MachineCartDeliveryMethod,
    deliveryAddressId?: number;
    firstName?: string;
    lastName?: string;
    streetAndNumber?: string;
    town?: string;
    zipCode?: string;
    country?: string;
    deliveryBranchId?: number;
}

export type FinancingPopUpType = {
    financingPopUpPhoneNumber: string | null;
    financingPopUpEmailAddress: string | null;
}

export type BranchContactInfo = {
    phone: string;
    email: string;
}

export type MachineBranch = {
    code: string;
    address: string;
};
export type MachineDeliveryAddress = {
    id: string;
    displayName: string;
};